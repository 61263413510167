<template>
  <div class="container_margin">
    <!-- start of Search Form -->
    <div class="card p-3">
      <!-- start of Jurisdiction input -->
      <b>
        {{ "Jurisdiction" }}
        <span
          id="tooltip-target-1"
          class="text-danger cursor-pointer"
        >*</span>
      </b>
      <b-tooltip
        target="tooltip-target-1"
        triggers="hover"
        placement="top"
        variant="secondary"
        id="tooltip1"
        title="Required"
      />
      <small>
        {{
          "(Processing time may increase by several minutes, depending on the number of jurisdictions chosen)"
        }}
      </small>

      <multiselect
        v-validate="'required'"
        class="mt-2"
        :maxHeight="150"
        :optionHeight="20"
        v-model="chosenJurisdiction"
        :options="jurisdictions"
        placeholder="Choose Country(s)"
        :multiple="true"
        :close-on-select="false"
        :searchable="false"
        selectLabel=""
        selectedLabel=""
        deselectLabel="Click to remove"
        :limit="7"
        name="Jurisdiction"
      ></multiselect>
      <small class="text-danger">
        {{ errors.first("Jurisdiction") }}
      </small>
      <!-- end of Jurisdiction input -->

      <!-- start of Enter Search input -->
      <div class="mt-3">
        <b>
          {{ "Enter Search" }}
          <span
            id="tooltip-target-2"
            class="text-danger cursor-pointer"
          >*</span>
        </b>
        <b-tooltip
          target="tooltip-target-2"
          triggers="hover"
          placement="top"
          variant="secondary"
          id="tooltip2"
          title="Required"
        />

        <b-form-textarea
          v-validate="'required'"
          name="Search"
          id="textarea"
          v-model.trim="search"
          :placeholder="'Search for Legislation / Statutes\nExample 1: can second wife claim husband\'s insurance money?\nExample 2: alcohol, driver, accident\nExample 3: domestic violence\nExample 4: Child Act\nExample 5: Penal Code Act 574 Section 375'"
          autocorrect="off"
          autocomplete="off"
          rows="5"
          max-rows="9"
          class="mt-2"
        ></b-form-textarea>

        <small :class="['float-right count_length',
          getWordCount(search) > 50 ? 'text-danger' : 'text-muted'
        ]">
          {{ search.length }} / 200
        </small>
        <small class="text-danger">
          {{ errors.first("Search") }}
        </small>
      </div>
      <!-- end of Enter Search input -->

      <!-- start of Footer Section -->
      <div class="text-end">
        <button
          @click="clear_search"
          class="btn btn-primary-outlined float-right"
        >
          {{ "Clear Search" }}
        </button>
        <button
          @click="search_result"
          class="btn btn-primary float-right mx-3"
        >
          {{ "Search" }}
        </button>
      </div>
      <!-- end of Footer Section -->
    </div>
    <!-- end of Search Form -->

    <!-- start of Search Result header -->
    <h5 class="mb-3 mt-4"><b>{{ `Search Result${searched_data.length > 1 ? "s" : ""}` }}</b></h5>

    <div class="row">
      <div
        v-if="searched_data.length !== 0 || searchQuery.length !== 0"
        class="col-5 mb-2"
      >
        <div id="searchCaseLaw" class="input-group">
          <input
            v-model="searchQuery"
            type="search"
            class="form-control"
            placeholder="Search by statement"
            aria-label="Search by statement"
            @input="onInput"
            @keyup.enter="searchClients"
          />
          <span @click="searchClients" class="input-group-text cursor-pointer">
            <b-icon icon="search" font-scale="1.2" />
          </span>
        </div>
      </div>

      <div v-if="searched_data.length !== 0 && rows > 10" class="col-7">
        <b-pagination
          class="float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        />
      </div>
    </div>
    <!-- end of Search Result header -->

    <!-- start of Search Results -->
    <table class="table card border shadow-sm p-2 table-card">
      <thead>
        <tr>
          <th scope="col" width="6%">{{ "ID" }}</th>
          <th scope="col" width="30%">{{ "Statement" }}</th>
          <th scope="col" width="18%">{{ "Jurisdiction(s)" }}</th>
          <th scope="col" width="12%" class="text-center">
            {{ "Created Date" }}
          </th>
          <th scope="col" width="12%" class="text-center">
            {{ "Created By" }}
          </th>
          <th scope="col" width="10%" class="text-center">
            {{ "Findings" }}
          </th>
          <th
            class="text-center"
            scope="col"
            width="15%"
            style="padding-right: 25px"
            v-if="permissions.includes('nexlaw.legislation-search')"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody>
        <PageLoader
          v-if="pageLoading"
          :loading="pageLoading"
        />

        <div
          v-else-if="searched_data.length === 0"
          class="container_margin"
        >
          <div
            class="row h-100 justify-content-center align-items-center"
          >
            <div class="col">
              <div class="mb-2 m-auto" style="width: 200px">
                <img src="@/assets/noData.png" alt="No Data Image" />
              </div>

              <p class="text-center text-blue font-weight-bold">
                {{ "No Data, Kindly start your search" }}
              </p>
            </div>
          </div>
        </div>

        <tr v-else :key="index" v-for="(item, index) in searched_data">
          <td width="6%">
            <p>{{ item.id }}</p>
          </td>
          <td width="30%">
            <p>{{ item.statement }}</p>
          </td>
          <td width="18%">
            <p>
              {{
                item.jurisdictions
                  ? getJurisdictionKey(item.jurisdictions).join(", ")
                  : "N/A"
              }}
            </p>
          </td>
          <td width="12%" class="text-center">
            <p class="text-muted">
              {{ item.created_at | formatDateLoc }}
            </p>
          </td>
          <td width="12%" class="text-center">
            <p class="text-muted font-weight-bold">
              {{ item.creator.name | username }}
            </p>
          </td>
          <td width="10%" class="text-center">
            <p>{{ item.total_findings }}</p>
          </td>
          <td
            class="text-center"
            width="15%"
            v-if="permissions.includes('nexlaw.legislation-search')"
          >
            <button
              v-if="item.completedPerCentage >= 100"
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>

            <button
              v-else-if="item.completedPerCentage == 'Not enough data'"
              class="btn btn-primary"
              @click="openCase(item.id)"
            >
              {{ "Open" }}
            </button>

            <button
              v-else
              class="btn btn-warning-outlined"
              @click="$toast.warning('Still in progress.')"
            >
              {{ item.completedPerCentage.toFixed(2) }} %
              <b-spinner label="Spinning" class="spinner"></b-spinner>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end of Search Results -->

    <div
      class="row mt-4"
      v-if="searched_data.length !== 0 && rows > 10"
    >
      <div class="col-5"></div>
      <div class="col-7">
        <b-pagination
          class="float-right"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          last-number
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LegislationSearch from "@/store/LegislationSearch.js";
import Multiselect from "vue-multiselect";
import { Authenticate, getWordCount, groupValidateInput, loopUpdate } from "../../store/utils";

export default {
  components: {
    PageLoader,
    Multiselect,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      rows: 100,
      perPage: 10,
      currentPage: 1,
      pageLoading: true,
      searchShow: false,
      search: "", // Input for the search query
      chosenJurisdiction: [], // Selected jurisdiction option

      jurisdictions: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],
      searched_data: [], // Array to hold the search results
      searchQuery: "",

      loop: false,
    };
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },

    getJurisdictionKey(jurisdiction) {
      return JSON
        .parse(jurisdiction)
        .map((country) => {
          if (country === "United States") country += " of America";
          return country;
        });
    },

    onInput() {
      if (this.searchQuery.length === 0) {
        this.searchClients();
      }
    },

    searchClients() {
      this.currentPage = 1;
      this.pageLoading = true;
      this.searched_data = [];

      if (this.searchQuery.length !== 0) {
        this.$router.push({
          query: { page: this.currentPage, statement: this.searchQuery },
        });
      } else {
        this.$router.push({
          query: { page: this.currentPage },
        });
      }

      this.loadData();
    },

    toastError(flag) {
      this.$toast.error({
        "empty": "Please fill in all required fields",
        "max": "Maximum word limit exceeded",
      }[flag]);
    },

    search_result() {
      this.$validator.validateAll();

      const Results = groupValidateInput([
        [this.chosenJurisdiction, "jurisdiction"],
        [this.search, "string", ["max50"]],
      ]);

      if (Results.findIndex(x => !x[0]) > -1) {
        this.toastError(Results.find(x => !x[0])[1]); return;
      }

      this.searchShow = true;

      var obj = {
        search_type: "and",
        jurisdictions: Results[0][1],
        statement: [Results[1][1]],
        location_id: 1,
        language: "en",
      };

      // console.log(obj); return;
      LegislationSearch.legislationSearch(obj)
        .then((response) => {
          Authenticate(this);
          this.$toast.success(response.data.message);
          this.currentPage = 1;
          this.searched_data = [];
          this.clear_search();
          this.$validator.reset();
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
          this.pageLoading = false;
        });
    },

    clear_search() {
      this.chosenJurisdiction = [];
      this.search = "";
      this.searchShow = false;
    },

    openCase(data) {
      this.$router.push({
        name: "Legislation_jurisdiction",
        params: {
          id: data,
        },
      });
    },

    loadData() {
      const me = this;
      function processData(data) {
        const DATA = data.data.data;
        const insert = me.searched_data.length > 0;
        me.pageLoading = false;
        me.rows = DATA.total;
        DATA.data.forEach(e => {
          const ID = me.searched_data.findIndex(old => old.id === e.id);
          try {
            e.statement = JSON.parse(e.statement).join(", ");
          } catch (error) {}

          if (ID > -1) {
            me.$set(me.searched_data, ID, e);
          } else if (insert) {
            me.searched_data.unshift(e);
          } else {
            me.searched_data.push(e)
          }

          if (e.completedPerCentage < 100) me.loop = true;
        })
      }

      loopUpdate(
        this,
        LegislationSearch.GetLegislationSearch,
        `&page=${this.currentPage}${
          this.searchQuery ? `&statement=${this.searchQuery}` : ""
        }`,
        processData
      )
    },
  },

  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != this.$route.query.page) {
        this.pageLoading = true;
        this.searched_data = [];

        if (this.searchQuery.length !== 0) {
          this.$router.push({
            query: { page: newPage, statement: this.searchQuery },
          });
        } else {
          this.$router.push({
            query: { page: newPage },
          });
        }
        clearTimeout(this.loop);
        this.loadData();
      }
    },
  },

  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
      this.searched_data = [];
    }

    const statement = this.$route.query.statement;
    if (statement) {
      this.searchQuery = statement;
    }

    if (localStorage.legisSearch_data) {
      var setValue = JSON.parse(localStorage.legisSearch_data);

      this.search = setValue.statement;

      localStorage.removeItem("legisSearch_data");
    }

    this.loadData();
  },

  destroyed() {
    clearTimeout(this.loop);
  },
};
</script>
<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
  margin-bottom: 0px;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

tbody tr:last-child td {
  border-bottom-width: 0px;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.span_badge {
  border: none;
  color: black;
  border-radius: 20px;
  font-size: 17px;
  padding: 0.1rem 0.2rem;
}

.court_badge {
  border: none;
  background: #d2d2d2;
  color: black;
  border-radius: 20px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
}

.court_badge .material-icons-outlined {
  font-size: 14px;
  translate: 0.2rem 0.1rem;
}

.select_court {
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.juris-modal {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

#tooltip {
  z-index: 99999;
}

#inputGroupSelect04 {
  z-index: 999;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
